import React, {useEffect,useState} from 'react';
import arrow from '../../../../assets/images/arrow.png';
import {images} from '@assets/images';

const SuccessPageView = () => {
    let storedProductData = sessionStorage.getItem('productData');
    if(!storedProductData){storedProductData = '{}'}
    const productItem = JSON.parse(storedProductData);
    const [progress,setProgress] = useState(0);

    useEffect(() => {
        document.title = "Gocard | Đăng ký thành công";
        console.log(productItem)
    }, []);
    useEffect(() => {
        if (productItem.navigateLink) {
            const duration = 3000;
            const intervalTime = 50;
            const totalSteps = duration / intervalTime;
            const increment = 100 / totalSteps;

            const interval = setInterval(() => {
                console.log(intervalTime)
                setProgress((prevProgress) => Math.min(prevProgress + increment, 100));
            }, intervalTime);

            const timer = setTimeout(() => {
                window.location.href = productItem.navigateLink;
                sessionStorage.removeItem('productData');
            }, duration);
            return () => {
                clearTimeout(timer);
                clearInterval(interval);
            };
        }
    },[productItem]);

    return (
        <>
            <div
                className="d-flex flex-column align-items-center justify-content-center"
                style={{
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    height: '100vh',
                    backgroundColor: '#d9fc00',
                    width: '100vw',
                }}
            >
                <div className="d-flex flex-row align-items-center justify-content-evenly w-70">
                    <img src={images.gocard.logoSuccess} alt="logo"
                         style={{
                             width: '72px',
                             height: '72px'
                         }}
                    />
                    <img src={arrow} alt="arrow" style={{width: '19px', height: '12px'}}/>
                    <img src={productItem?.iconUrl} alt="icon"
                         style={{
                             height:'72px',
                             width: '72px',
                             backgroundColor: 'white',
                             objectFit: 'contain',
                             borderRadius: '50%',
                         }}/>
                </div>
                <div className="progress" style={{width: '80%', marginTop: '20px'}}>
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{width: `${progress}%`, backgroundColor: '#007bff'}}
                        aria-valuenow={progress}
                        aria-valuemin={0}
                        aria-valuemax={100}
                    />
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center my-5 mx-3">
                    <p className="m-0">
                        Xử lý yêu cầu thành công!
                    </p>
                    <p className="text-center m-0">
                        Hệ thống sẽ tự động chuyển hướng đến trang website của <span
                        className="fw-bold">{productItem.partner}</span> trong 3s
                    </p>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center position-relative">
                    <img
                        src={productItem?.imageUrl}
                        alt="success"
                        style={{
                            width: '5rem', position: 'absolute', top: '0.5rem', left: '2.7rem',
                        }}
                    />
                    <img src={images.gocard.success} alt="success" style={{width: '20rem'}}/>
                </div>
            </div>
        </>
    );
}

export default React.memo(SuccessPageView);
