import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import './LoanDetailPageView.scss';
// import {images} from "@assets/images";
import publicService from "../../../service/PublicService";
import {Spin} from "antd";
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";

interface LoanItem {
    id: number;
    code:string;
    name: string;
    iconUrl: string;
    loanLimit: number;
    term: number;
    interestRate: number;
    benefits: number;
}

const LoanDetailPageView: React.FC = () => {
    const [banner, setBanner] = useState<string | null>(null);
    const [colorBody, setColorBody] = useState<string>('#eefd8c');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const location = useLocation<{ loanItem: LoanItem }>();

    const loanItem = location.state?.loanItem;

    const searchParams = new URLSearchParams(location.search);
    const partner = searchParams.get('partner');

    useEffect(() => {
        if (partner === 'tnex') {
            document.body.classList.add('partner-tnex-detail');
        } else {
            document.body.classList.add(':root');
        }

        const fetchPartnerCss = async (partner: string | null) => {
            if (partner) {
                try {
                    const resp = await publicService.getPartnerCssByProductPartner(partner);
                    const data = resp.data;

                    setBanner(data.bannerPartnerUrl);
                    setColorBody(data.colorBody);
                } catch {

                }
            }

        }

        fetchPartnerCss(partner);
    }, [partner]);

    if (!loanItem) {
        return <p>Không có thông tin khoản vay.</p>;
    }

    const handleCreateLoan = (loanItem: LoanItem) => {
        setLoading(true);
        sessionStorage.setItem('productData', JSON.stringify(loanItem));
        try {
            history.push('/register-loan', {loanItem});
        } catch (error) {
            console.log("Error", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Spin spinning={loading}>
                <div className="container">
                    {banner && (
                        <div className="banner-container">
                            <div>
                                <img src={banner} alt="Partner Banner"/>
                            </div>
                        </div>
                    )}
                    <p className="loan-detail-header">Chi tiết sản phẩm vay</p>
                    <div className="loan-detail" style={{backgroundColor: colorBody}}>
                        <div className="header" style={{display: 'flex', alignItems:'center',marginTop:'15px'}}>
                            <img src={loanItem.iconUrl} alt="loan-img" className="logo-loan-detail"
                                 style={{width: '144px'}}/>
                            <div className="pd-left-10" >
                            <h2 className="title-loan-detail">{loanItem.name}</h2>
                            <button className="button-loan-detail" onClick={() => handleCreateLoan(loanItem)}>Đăng ký
                                ngay
                            </button>
                            <a className='back-btn'
                               onClick={() => history.goBack()}>
                                &lt; Quay lại
                            </a>
                            </div>
                        </div>
                        <div className="infoRow-loan-detail">
                            <span className="label-loan-detail">Hạn mức khoản vay:</span>
                            <span className="value-loan-detail">3 triệu - {loanItem.loanLimit} triệu</span>
                        </div>
                        <div className="infoRow-loan-detail">
                            <span className="label-loan-detail">Lãi suất:</span>
                            <span className="value-loan-detail">Từ {loanItem.interestRate}%/năm</span>
                        </div>
                        <div className="infoRow-loan-detail">
                            <span className="label-loan-detail">Thu nhập:</span>
                            <span className="value-loan-detail">Tối thiểu 3 triệu</span>
                        </div>
                        <div className="infoRow-loan-detail">
                            <span className="label-loan-detail">Tuổi:</span>
                            <span className="value-loan-detail">Từ 18 tuổi</span>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton/>
            </Spin>
        </>
    );
}

export default LoanDetailPageView;
