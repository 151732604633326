import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import ScrollToTopButton from '../../elements/ScrollToTopButton/ScrollToTopButton';
import './ListCardPageView.scss';
import publicService from "../../../service/PublicService";
import {notification, Spin} from "antd";

interface RouteParams {
    type: string;
}

const ListCardPageView = () => {
    const history = useHistory();
    const [listCard, setListCard] = useState([])
    const [filterType, setFilterType] = useState<string>('Tất cả')
    const [activeButton, setActiveButton] = useState<string>(filterType);
    const [cardTypes, setCardTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorNotify, contextHolder] = notification.useNotification();
    const [cardType, setCardType] = useState(filterType);
    const [visibleCount, setVisibleCount] = useState(3);

    const {type} = useParams<RouteParams>();

    const storedLink = sessionStorage.getItem('link') || '';
    const hasOtpSub = storedLink.includes('sub=otp');
    const passOTP = sessionStorage.getItem("otp-pass")
    useEffect(() => {
        document.title = "Gocard | Danh sách thẻ tín dụng"
        window.scroll(0, 0);
        setLoading(true);
        fetchCardTypes();
        getCard();
        let reqInfoStr = sessionStorage.getItem('req_info');
        if(reqInfoStr!=null){
            let jo = JSON.parse(reqInfoStr);
            if(jo.creditCardType){
                setFilterType(jo.creditCardType);
            }
        }
    }, []);

    async function getCard() {
        const resp = await publicService.getAllCreditCard()
        setListCard( resp.data)
        setLoading(false);
    }

    const handleButtonClick = (buttonType: string) => {
        setCardType(buttonType);
        setActiveButton(buttonType);
        setVisibleCount(3);
    };

    const handleSeeMore = () => {
        setVisibleCount((prevCount) => prevCount + 3);
    };

    const filterCreditCards = (listCard: any, filterType: string) => {
        if (filterType === 'Tất cả') {
            return listCard;
        }
        return listCard.filter((card: any) => card.types.includes(filterType));
    };

    const resultCard = filterCreditCards(listCard, cardType);

    function formatNumberCustom(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const handleDetailCard = (cardItem: any) => {
        setLoading(true);
        try {
            history.push('/card-detail', {cardItem: cardItem});
        } catch (error) {
            console.log("Error", error);
        } finally {
            setLoading(false);
        }
    }

    const handleCreate = async (card: any) => {
        sessionStorage.setItem('productData', JSON.stringify(card));
        setLoading(true);
        try {
            publicService.saveLeadProduct({
                name: card.name,
                creditLimitTo: card.creditLimitTo,
                creditLimitFrom: card.creditLimitFrom,
                annualFee: card.annualFee,
                cashAdvanceFee: card.cashAdvanceFee,
                monthlyIncome: card.monthlyIncome,
                requiredAge: card.requiredAge,
                features: card.features,
                interestRate: card.interestRate,
                uuid: sessionStorage.getItem('user_id'),
            })
            const resp = await publicService.applyRegister('dang-ky', type, {product: card.name,});
            if (resp.status === 200) {
                if (hasOtpSub || passOTP) {
                    if (card.navigateLink) {
                        history.push('/success');
                    } else {
                        history.push('/success-view');
                    }
                } else {
                    history.push(`/receive-OTP/${type}`)
                }
                setLoading(false);
            }
        } catch (error: any) {
            errorNotify.error({
                message: 'Lỗi',
                description: error.response?.data || 'Có lỗi xảy ra. Vui lòng thử lại sau!',
            });
        } finally {
            setLoading(false);
        }
        return;
    }

    const fetchCardTypes = async () => {
        let cardTypeStr = sessionStorage.getItem('card_type')
        if(cardTypeStr){
            setCardTypes(JSON.parse(cardTypeStr))
        }else {
            const response = await publicService.getAllCardTypes();
            if (response.status === 200) {
                setCardTypes(response.data);
            }
        }
    };

    return (
        <>
            {contextHolder}
            <div className='container'>
                <Spin spinning={loading}>
                    <div className='btn-list-card'>
                        <button className={`btn-all ${activeButton === 'Tất cả' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('Tất cả')}>
                            Tất cả
                            <div className="line"></div>
                        </button>
                        {cardTypes.map((type: any) => (
                            <button key={type.value}
                                    className={`btn-all ${activeButton === type.value ? 'active' : ''}`}
                                    onClick={() => handleButtonClick(type.value)}>
                                {type.value}
                                <div className="line"></div>
                            </button>
                        ))}
                    </div>
                    <h1 className='text-list-card'>DANH SÁCH THẺ TÍN DỤNG</h1>
                    <div className='list'>
                        {resultCard.slice(0, visibleCount).map((card: any) => (
                            <div key={card.id} className='list-card mb-3'>
                                <div className={`card-icon ${card.partner.toLowerCase().includes('vpbank') ? 'vp-icon'
                                    : card.partner.toLowerCase().includes('tp') ? 'tp-icon'
                                        : card.partner.toLowerCase().includes('vib') ? 'vib-icon' : ''}`}>
                                    <img src={card.iconUrl} alt="card-icon"/>
                                </div>
                                <span className='pt-2'>{card.name}</span>
                                <div className='card-open row'>
                                    <div className="col-6">
                                        <img src={card.imageUrl}
                                             className={
                                                 card.name.toLowerCase().includes('tpbank evo') ? 'tp-img' :
                                                     card.name.toLowerCase().includes('vpbank z') ? 'vp-img' :
                                                         card.name.toLowerCase().includes('super shopee platinum') ? 'vp-img' : ''
                                             }
                                             alt="card"/>
                                    </div>
                                    <div
                                        className="col-6 d-flex flex-column justify-content-center align-items-center open-card">
                                        <button className="mb-2" onClick={() => handleCreate(card)}>Mở thẻ ngay
                                        </button>
                                        <a onClick={() => handleDetailCard(card)}>Tìm hiểu thêm &gt;</a>
                                    </div>
                                </div>
                                <div className='credit-limit'>
                                    <span className='span-title'>Loại thẻ:</span>
                                    <span className="span-content">
                                        {card.types}
                                    </span>
                                </div>
                                <div className='credit-limit mt-2'>
                                    <span className='span-title'>Phí thường niên:</span>
                                    <span className="span-content">{formatNumberCustom(card.annualFee)}</span>
                                </div>
                                <div className='credit-limit mt-2'>
                                    <span className='span-title'>Lãi suất năm:</span>
                                    <span className="span-content">{card.interestRate}</span>
                                </div>
                            </div>
                        ))}
                        {visibleCount < resultCard.length && (
                            <a className='see-more' onClick={handleSeeMore}>Xem thêm -&gt;</a>
                        )}
                    </div>

                    <ScrollToTopButton/>
                </Spin>
            </div>
        </>
    );
}

export default React.memo(ListCardPageView);
