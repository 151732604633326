import React, {useEffect, useState} from 'react';
import './PersonalInfoPageView.scss';
import cities from '../../../utils/city.json';
import districts from '../../../utils/district.json';
import {useHistory, useParams} from 'react-router-dom';
import {notification, Spin} from 'antd';
import Select, {SingleValue} from 'react-select';
import publicService from 'src/app/service/PublicService';

interface RouteParams {
    type: string;
}

const PersonalInfoPageView = () => {

    const {type} = useParams<RouteParams>();
    const history = useHistory();
    const [errorNotify, contextHolder] = notification.useNotification();
    const [loading, setLoading] = useState(false);

    const storedLink = sessionStorage.getItem('link') || '';
    const hasOtpSub = storedLink.includes('sub=otp');

    const [contactInfo, setContactInfo] = useState({
        dob: '2000-01-01',
        selectedCity: '',
        cityCode: '',
        selectedDistrict: null,
        inputAddress: '',
    });

    useEffect(() => {
        document.title = "Gocard | Nhập thông tin cá nhân"
    }, []);

    const handleCityChange = (selectedOption: SingleValue<any>) => {
        setContactInfo({
            ...contactInfo,
            selectedCity: selectedOption?.value || '',
            cityCode: selectedOption?.code || '',
            selectedDistrict: null,
        });
    };

    const handleDistrictChange = (selectedOption: any) => {
        setContactInfo({
            ...contactInfo,
            selectedDistrict: selectedOption?.value || null,
        });
    };

    // const onChange = (date: Dayjs | null, dateString: string) => {
    //     setContactInfo(prevContactInfo => ({
    //         ...prevContactInfo,
    //         dob: date ? dateString : '',
    //     }));
    // };

    const handleDateChange = (e: any) => {
        const value = e.target.value;
        setContactInfo({...contactInfo, dob: value});
    };

    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setContactInfo({
            ...contactInfo,
            inputAddress: value,
        })
    }

    const handleSubmit = async () => {
        await submitData(true)
    };

    const handleSubmitSkip = async () => {
        await submitData(false)
    };

    const submitData = async (isSubmit: boolean) => {
        setLoading(true);
        try {
            let submitSuccess = null;
            if (isSubmit) {
                let step = 'nhap-thong-tin-ca-nhan';
                const formattedDate = contactInfo.dob.split('-').reverse().join('-');
                const leadDto = {
                    dob: formattedDate,
                    address: contactInfo.inputAddress,
                    city: contactInfo.selectedCity,
                    district: contactInfo.selectedDistrict,
                };
                const resp = await publicService.applyRegister(step, type, leadDto);
                submitSuccess = resp.status;
            }
            if (!isSubmit || submitSuccess === 200) {
                const productDataStr = sessionStorage.getItem('productData');
                let link = sessionStorage.getItem('link');
                if (link === null) link = "";
                if (hasOtpSub) {
                    if (productDataStr) {
                        let productData = JSON.parse(productDataStr)
                        if (productData.navigateLink) {
                            history.push({
                                pathname: `/success`
                            });
                        } else {
                            history.push('/success-view');
                        }
                    } else {
                        if (type === 'credit-card') {
                            history.push(`/danh-sach-the/${type}`);
                        }else if (type === 'loan') {
                            history.push({
                                pathname: `/success-view`
                            });
                        }
                    }
                } else {
                    history.push({
                        pathname: `/receive-OTP/${type}`
                    });
                }
            }
        } catch (error: any) {
            console.log(error)
            errorNotify.error({
                message: 'Lỗi',
                description: error.response?.data || 'An error occurred',
            });
        } finally {
            setLoading(false);
        }
    };

    const cityOptions = Object.entries(cities).map(([code, city]) => ({
        code: code,
        value: city.name_with_type,
        label: city.name_with_type
    })).sort((a, b) => a.label.localeCompare(b.label));

    const districtOptions = Object.entries(districts)
        .filter(([, district]) => district.parent_code === contactInfo.cityCode)
        .map(([, district]) => ({
            value: district.name_with_type,
            label: district.name_with_type
        })).sort((a, b) => a.label.localeCompare(b.label));

    return (
        <>
            {contextHolder}
            <div className="mx-3 mt-4 personal-info-form">
                <Spin spinning={loading}>
                    <p style={{fontWeight: '1000', marginBottom: '0.2em'}} className="fs-4 title-page">
                        Thêm thông tin cá nhân để chúng tôi tìm đối tác phù hợp cho bạn
                    </p>
                    <div style={{marginTop: '50px'}}>
                        <div className="mb-3 contact-input">
                            <p style={{fontSize: '0.9em', fontWeight: '600'}} className="fw-semibold m-0 svn-font">
                                Ngày sinh
                            </p>
                            <input
                                type="date"
                                value={contactInfo.dob}
                                onChange={handleDateChange}
                                placeholder="Chọn ngày sinh"
                                className="input-date"
                            />
                        </div>
                        <div className="mb-3 contact-input">
                            <p style={{fontSize: '0.9em', fontWeight: '600'}} className="fw-semibold m-0 svn-font">Địa
                                chỉ</p>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <Select
                                        options={cityOptions}
                                        onChange={handleCityChange}
                                        value={cityOptions.find(option => option.value === contactInfo.selectedCity)}
                                        placeholder="Chọn Tỉnh/Thành"
                                        className="basic-single"
                                    />
                                </div>
                                <div className="col-6">
                                    <Select
                                        className="basic-single"
                                        options={districtOptions}
                                        onChange={handleDistrictChange}
                                        value={contactInfo.selectedDistrict ? districtOptions.find(option => option.value === contactInfo.selectedDistrict) : null} // Updated value
                                        placeholder="Chọn Quận/Huyện"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 contact-input">
                            <p style={{fontSize: '0.9em', fontWeight: '600'}}
                               className="fw-semibold m-0 svn-font">Địa chỉ cụ thể</p>
                            <input
                                type="text"
                                className="form-control border border-black"
                                id="address"
                                placeholder="Vui lòng nhập"
                                value={contactInfo.inputAddress}
                                onChange={handleAddressChange}
                                required
                            />
                        </div>
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="btn-confirm" onClick={handleSubmit}>
                                {type === 'credit-card' ? 'Tìm thẻ phù hợp' : 'Tìm khoản vay phù hợp'}
                            </button>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <button type="submit" className="btn-skip" onClick={handleSubmitSkip}>
                                Bỏ qua
                            </button>
                        </div>
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default React.memo(PersonalInfoPageView);
