import React, {useEffect, useState} from 'react';
import './ReceiveOTPPageView.scss';
import phoneIcon from '@assets/icon/phone-icon.svg';
import emailIcon from '@assets/icon/email-icon.svg';
import zaloIcon from '@assets/icon/zalo.svg';

import {useHistory, useParams} from 'react-router-dom';
import publicService from 'src/app/service/PublicService';
import {notification, Spin} from 'antd';

interface RouteParams {
    type: string;
}

const ReceiveOTPPageView: React.FC = () => {
    const history = useHistory();

    const [api, contextHolder] = notification.useNotification();
    const [isDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    //const [email, setEmail] = useState('');
    const [phoneCut, setPhoneCut] = useState('');
    const [emailCut, setEmailCut] = useState('');
    const {type} = useParams<RouteParams>();



    useEffect(() => {
        document.title = "Gocard | Nhận mã OTP"
        let infoStr = sessionStorage.getItem("contact_info");
        if(infoStr) {
            const  infoObj = JSON.parse(infoStr);
            setPhone(infoObj.phone)
            setPhoneCut(infoObj.phone.substring(phone.length - 3))
            //setEmail(`${infoObj.emailUsername}@${infoObj.emailDomain}`)
            setEmailCut(`${infoObj.emailUsername ? infoObj.emailUsername.slice(0, 3) + "*****" : ''}@${infoObj.emailDomain}`)
        }
    }, []);

    const handleReceivePhoneOtp = async (step: string,typeOTP: string) => {
        setLoading(true);
        const otpSentTime = sessionStorage.getItem('otpSentTime');
        const currentTime = Date.now();

        if (otpSentTime && (currentTime - parseInt(otpSentTime)) < 120000) {
            api.warning({
                message: 'Thông báo',
                description: 'Bạn chỉ có thể yêu cầu OTP sau 120 giây kể từ lần gửi trước.',
            });
            setLoading(false);
            return;
        }
        try {
            const resp = await publicService.applyRegister(step, type, {typeOTP: typeOTP});
            if (resp.status === 200) {
                sessionStorage.setItem('otpSentTime', currentTime.toString());
                history.push({
                    pathname: `/verify-otp/${type}`,
                    state: { typeOTP: typeOTP },
                });
            }
        } catch (error: any) {
            api.error({
                message: 'Error',
                description: error.response.data,
            });
        } finally {
            setLoading(false);
        }
    };

    // const  backToInfo = () =>{
    //     history.push(`/contact-info/credit-card`);
    // }

     function phoneOTPSubmit (){
        setLoading(true);
        try {
            handleReceivePhoneOtp('gui-otp','phone');
        } catch (error) {
            console.log("Err", error);
        } finally {
            setLoading(false);
        }
        return;
    };
    function zaloOTPSubmit (){
        setLoading(true);
        try {
            handleReceivePhoneOtp('gui-otp','zalo');
        } catch (error) {
            console.log("Err", error);
        } finally {
            setLoading(false);
        }
        return;
    };

    return (
        <>
            {contextHolder}
            <Spin spinning={loading}>
                <div className="container-receive">
                    <span className='receive-otp'>Nhận mã OTP</span>
                    <p>Chọn phương thức nhận mã xác thực đăng ký trên<br/> Gocard</p>
                    <div className={`phone`} onClick={phoneOTPSubmit}>
                        <div className="div-img">
                        <img src={phoneIcon} alt="phone-icon"/>
                        </div>
                        <span>Sử dụng điện thoại *****{phoneCut} của bạn để nhận mã xác thực</span>
                    </div>
                    <div className={`zalo`} onClick={zaloOTPSubmit}>
                        <div className="div-img">
                        <img src={zaloIcon} alt="zalo-icon"/>
                        </div>
                        <span>Sử dụng tài khoản zalo *****{phoneCut} của bạn để nhận mã xác thực</span>
                    </div>
                    <div className={`email ${isDisabled ? 'disabled' : ''}`}>
                        <div className="div-img">
                        <img src={emailIcon} alt="email-icon"/>
                        </div>
                        <span>Sử dụng địa chỉ email {emailCut} của bạn để nhận mã xác thực</span>
                    </div>
                    {/*<button className='btn-back-otp' disabled>Quay lại</button>*/}
                </div>
            </Spin>
        </>
    )
}

export default React.memo(ReceiveOTPPageView);
