import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import publicService from 'src/app/service/PublicService';
import './ListLoanPageView.scss';
import {Spin} from "antd";
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";

interface LoanItem {
    id: number;
    code: string;
    name: string;
    partner?: string;
    iconUrl: string;
    loanLimit: number;
    term: number;
    interestRate: number;
    interestRateUnit: string;
    benefits: number;
    priority: number;
}

const getAllLoanPackages = async (partner?: string) => {
    try {
        if (partner) {
            document.body.classList.add(`partner-${partner}`)
            const resp = await publicService.getAllLoanByPartner(partner);
            return resp.data;
        } else {
            document.body.classList.add(':root');
            const resp = await publicService.getAllLoanPackages();
            return resp.data;
        }
    } catch (error) {
        console.error("Error", error);
        return [];
    }
}

const ListLoanPageView: React.FC = () => {
    const [loanPackage, setLoanPackage] = useState<LoanItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [banner, setBanner] = useState<string | null>(null);
    const [colorBody, setColorBody] = useState<string>('#eefd8c');
    const history = useHistory();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let partner = searchParams.get('partner') || '';

    useEffect(() => {
        const currentUrl = window.location.href;
        const subOtp = searchParams.get('sub');
        sessionStorage.setItem('link', currentUrl);
        if (subOtp === 'otp') {
            sessionStorage.setItem('link', currentUrl);

            const url = new URL(window.location.href);
            url.searchParams.delete('sub');

            window.history.replaceState({}, document.title, url.toString());
        }

        const fetchPartnerCss = async (partner: string | null) => {
            if (partner) {
                try {
                    const resp = await publicService.getPartnerCssByProductPartner(partner);
                    const data = resp.data;

                    setBanner(data.bannerPartnerUrl);
                    let tmp = data.colorBody.split(",")
                    setColorBody(tmp[0]);
                    if(tmp.length>=2) {
                        sessionStorage.setItem('color-header', tmp[1]);
                    }
                    if(tmp.length>=3) {
                        sessionStorage.setItem('background-header', tmp[2]);
                    }
                } catch {

                }
            }

        }

        const fetchData = async () => {
            const data = await getAllLoanPackages(partner);
            setLoanPackage(data);
        };

        fetchPartnerCss(partner);
        fetchData();
        window.scroll(0, 0);
    }, [partner]);

    useEffect(() => {
        document.title = "Gocard | Vay tiêu dùng"
    }, []);

    const handleCreateLoanPackage = (loanPackageItem: LoanItem) => {
        setLoading(true);
        sessionStorage.setItem('productData', JSON.stringify(loanPackageItem));
        partner = (''+loanPackageItem.partner).toLowerCase()
        sessionStorage.setItem('productData', JSON.stringify(loanPackageItem));
        try {
            //if (partner) {
                history.push({
                    pathname: '/register-loan',
                    state: {loanItem: loanPackageItem},
                    search: `?partner=${partner}`
                });
            /*} else {
                history.push('/', {loanItem: loanPackageItem, active: 'loan'})
            }*/
        } catch (error) {
            console.log("Error", error)
        } finally {
            setLoading(false)
        }
    }

    const handleLoanDetail = (loanPackageItem: LoanItem) => {
        setLoading(true);
        try {
            if (partner) {
                history.push({
                    pathname: '/loan-detail',
                    state: {loanItem: loanPackageItem},
                    search: `?partner=${partner}`
                });
            } else {
                history.push('/loan-detail', {loanItem: loanPackageItem});
            }
        } catch (error) {
            console.log("Error", error)
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Spin spinning={loading}>
                <div className="container">
                    {banner && (
                        <div className="banner-container">
                            <div>
                                <img src={banner} alt="Partner Banner"/>
                            </div>
                        </div>
                    )}
                    <h1 className='text-list-loan'>Danh sách sản phẩm vay</h1>
                    {loanPackage.length > 0 ? (
                        loanPackage.map((loanPackage: LoanItem) => (
                            <div key={loanPackage.id} className='advert-container'>
                                <div className='advert'>
                                    <div
                                        className={`img-advert ${partner === 'fe' || loanPackage?.partner?.toLowerCase().includes('fe') ? 'fe-icon' : ''}`}>
                                        <img src={loanPackage.iconUrl} alt="loan"/>
                                    </div>
                                    <span>Lãi suất hấp dẫn chỉ từ {loanPackage.interestRate}%/{loanPackage.interestRateUnit.toLowerCase()}</span>
                                    <div className='text-advert' style={{backgroundColor: colorBody}}>
                                        <p>Lãi suất<br/> <b>từ {loanPackage.interestRate}%</b> <br/>{loanPackage.interestRateUnit}</p>
                                        <p>Ưu đãi<br/> <b>{loanPackage.term}</b> <br/>Tháng</p>
                                    </div>
                                    <button onClick={() => handleCreateLoanPackage(loanPackage)}>Đăng ký ngay</button>
                                    <a className='see-detail-loan' onClick={() => handleLoanDetail(loanPackage)}>
                                        Xem chi tiết
                                    </a>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className='text-err'>Không có khoản vay nào để hiển thị.</p>
                    )}
                </div>
                <ScrollToTopButton/>
            </Spin>
        </>
    );
}

export default React.memo(ListLoanPageView);
